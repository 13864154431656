import { get, isEmpty } from 'lodash';
import ProfileClientReview from './ProfileClientReview';
import ProfileClientReviewsEmpty from './ProfileClientReviewsEmpty';
import ReviewPagination from '../ReviewPagination/ReviewPagination';
import LoadingSpinner from '../LoadingSpinner';
import { formatPlural, getResultsThresholdCount } from '../../utils/utilities';
import ProfileClientReviewsLabel from "./ProfileClientReviewsLabel";
import Link from 'next/link'
import Button from '../Inputs/Button';

const defaultProps = {
    showReadMoreButton: true,
    loading: false,
    reviewsOffset: 0,
    profileName: '',
    handleShowMoreReviews: () => {},
    pagination: {},
};

function ProfileClientReviews(props) {
    const renderReview = (review, i) => {
        const data = get(props, ['profileData'], null);

        if (data === null) {
            return null;
        }

        const { full_name, first_name, vertical } = data;

        return (
            <ProfileClientReview
                key={i}
                review={review}
                type={review.reviewer_type}
                professionalName={full_name}
                professionalFirstName={first_name}
                professionalVertical={vertical}
            />
        );
    }

    const renderReviewList = () => {
        const {
            handleShowMoreReviews,
            reviewsOffset,
            reviewsTotalCount,
            reviews,
            showReadMoreButton,
            profileData,
            pagination,
        } = props;

        const nextResultsCount = getResultsThresholdCount(reviewsTotalCount, reviewsOffset, 5);
        const isReviewsEmpty = isEmpty(reviews)
        const isPaginationEmpty = isEmpty(pagination)

        return (
            <>
                {isReviewsEmpty &&
                    <div className='bg-white p-8 shadow-md rounded-lg'>
                        <p className='text-lg m-0'>
                            No reviews matching your filter selections.
                        </p>
                    </div>
                }

                {!isReviewsEmpty && reviews.map((review, i) => renderReview(review, i))}

                {showReadMoreButton &&
                    <div className='flex justify-center gap-4 mt-2 max-sm:flex-col max-sm:items-center max-xs:mb-5'>
                        {0 < nextResultsCount &&
                            <div className='max-xs:w-full'>
                                <Button
                                    className='max-xs:w-full'
                                    onClick={handleShowMoreReviews}
                                    text={`Read ${nextResultsCount} more ${formatPlural(reviews.length, 'Review', 'Reviews')}`}
                                />
                            </div>
                        }

                        {profileData &&
                            <div className='max-xs:w-full'>
                                <Link href={ `/${props.profileData.permalink.url}/reviews`} passHref>
                                    <Button
                                        className='max-xs:w-full'
                                        text='Read all reviews'
                                    />
                                </Link>
                            </div>
                        }
                    </div>
                }

                {!isPaginationEmpty &&
                    <div className='text-center max-xs:mb-3 max-xs:-mt-6'>
                        <ReviewPagination
                            baseUrl={pagination.baseUrl}
                            pagesCount={pagination.pagesCount}
                        />
                    </div>
                }
            </>
        );
    }

    const {
        profileData,
        loading,
        profileName,
        reviewsTotalCount,
    } = props;

    const profileReviewCount = get(profileData, ['review_count'], null);
    const hasReviewsFromOtherFirms = get(profileData, 'professional_key_attributes.reviews_from_other_firm', false);

    return (
        <section className='bg-very-pale-mint-green p-8 max-xs:p-2 flex flex-col gap-6 max-xs:pt-3' id="reviews">
            { hasReviewsFromOtherFirms && <ProfileClientReviewsLabel />}

            { props.children }
            
            {
                loading
                ? <LoadingSpinner color="inherit" />
                : (0 < profileReviewCount || reviewsTotalCount)
                    ? renderReviewList()
                    : <ProfileClientReviewsEmpty name={profileName} />
            }
        </section>
    );
}

ProfileClientReviews.defaultProps = defaultProps;

export default ProfileClientReviews;
