import {twMerge} from "tailwind-merge";

function CircularProgressGradient({ className, rating, maxRating}) {
    return (
        <>
            <div className={twMerge('relative p-0 inline-block w-[100px] h-[100px]', className)}>
                <svg className={'w-full h-full'} viewBox="-10 -10 220 220">
                    <g fill="none" strokeWidth="7" transform="translate(100,100)">
                        <path d="M 0,-100 A 100,100 0 0,1 86.6,-50" stroke="url(#cl1)"/>
                        <path d="M 86.6,-50 A 100,100 0 0,1 86.6,50" stroke="url(#cl2)"/>
                        <path d="M 86.6,50 A 100,100 0 0,1 0,100" stroke="url(#cl3)"/>
                        <path d="M 0,100 A 100,100 0 0,1 -86.6,50" stroke="url(#cl4)"/>
                        <path d="M -86.6,50 A 100,100 0 0,1 -86.6,-50" stroke="url(#cl5)"/>
                        <path d="M -86.6,-50 A 100,100 0 0,1 0,-100" stroke="url(#cl6)"/>
                    </g>
                </svg>
                <svg className={'w-full h-full absolute left-0 top-0 -rotate-90'} viewBox="-10 -10 220 220">
                    <path
                        className={'animate-[load_2s] fill-none stroke-[10px] [stroke-dasharray:629] stroke-white opacity-100'}
                        d="M200,100 C200,44.771525 155.228475,0 100,0 C44.771525,0 0,44.771525 0,100 C0,155.228475 44.771525,200 100,200 C155.228475,200 200,155.228475 200,100 Z" strokeDashoffset={rating * 629 / maxRating}></path>
                </svg>
            </div>

            <svg width="0" height="0">
                <defs>
                    <linearGradient id="cl1" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="1" y2="1">
                        <stop stopColor="#4BC1CA"/>
                        <stop offset="100%" stopColor="#4BC1CA"/>
                    </linearGradient>
                    <linearGradient id="cl2" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
                        <stop stopColor="#4BC1CA"/>
                        <stop offset="100%" stopColor="#4BC1CA"/>
                    </linearGradient>
                    <linearGradient id="cl3" gradientUnits="objectBoundingBox" x1="1" y1="0" x2="0" y2="1">
                        <stop stopColor="#4BC1CA"/>
                        <stop offset="100%" stopColor="#4BC1CA"/>
                    </linearGradient>
                    <linearGradient id="cl4" gradientUnits="objectBoundingBox" x1="1" y1="1" x2="0" y2="0">
                        <stop stopColor="#4BC1CA"/>
                        <stop offset="100%" stopColor="#4BC1CA"/>
                    </linearGradient>
                    <linearGradient id="cl5" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="0" y2="0">
                        <stop stopColor="#4BC1CA"/>
                        <stop offset="100%" stopColor="#4BC1CA"/>
                    </linearGradient>
                    <linearGradient id="cl6" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="1" y2="0">
                        <stop stopColor="#4BC1CA"/>
                        <stop offset="100%" stopColor="#4BC1CA"/>
                    </linearGradient>
                </defs>
            </svg>
        </>
    );
}

export default CircularProgressGradient;