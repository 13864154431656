import PropTypes from 'prop-types';
import CircularProgressGradient from './vectors/CircularProgressGradient';
import { defaultPictureAvatar } from '../assets/images';
import { get } from 'lodash';
import { twMerge } from "tailwind-merge";

const propTypes = {
    professionalName: PropTypes.string,
    xs: PropTypes.bool,
    inlineBlock: PropTypes.bool,
};

const defaultProps = {
    professionalName: 'Profile',
    xs: false,
    inlineBlock: false,
};

function ScoreAvatar({image, professional, ratingValue, ratingMax, inlineBlock, professionalName, xs, md, featuredAdvisersStyle}) {
    const getProfessionalPhoto = (professional, size) =>  get(professional, ['professional_photo', 'urls', size], defaultPictureAvatar)
    const smallUrl = professional ? getProfessionalPhoto(professional, 'small') : image;
    const standardUrl = professional ? getProfessionalPhoto(professional, 'standard') : image;

    return (
        <div className={`relative m-2 ${inlineBlock ? 'inline-block' : 'block'}`}>
            <CircularProgressGradient
                rating={ratingValue}
                maxRating={ratingMax}
                className={twMerge(
                    `w-[182px] h-[182px] m-1.5`,
                    `${xs ? 'w-[60px] h-[60px]': '' }`,
                    `${md ? 'w-[100px] h-[100px]': '' }`,
                    `${featuredAdvisersStyle ? 'w-[152px] h-[152px]': '' }`,
                )}
            />
            <div className={'block sm:hidden'}>
                <img
                    src={smallUrl}
                    alt={professionalName}
                    className={twMerge(
                        `w-[160px] h-[160px] border border-solid border-white rounded-[50%] m-4 absolute top-0 left-0`,
                        `${xs ? 'w-[50px] h-[50px] m-[10px]': '' }`,
                        `${md ? 'w-[84px] h-[84px] m-[13px]': '' }`,
                        `${featuredAdvisersStyle ? 'w-[130px] h-[130px]': '' }`,
                    )}
                />
            </div>
            <div className={'hidden sm:block'}>
                <img
                    src={standardUrl}
                    alt={professionalName}
                    className={twMerge(
                        `w-[160px] h-[160px] border border-solid border-white rounded-[50%] m-4 absolute top-0 left-0`,
                        `${xs ? 'w-[50px] h-[50px] m-[10px]': '' }`,
                        `${md ? 'w-[84px] h-[84px] m-[13px]': '' }`,
                        `${featuredAdvisersStyle ? 'w-[130px] h-[130px]': '' }`,
                    )}
                />
            </div>
        </div>
    );
}

ScoreAvatar.defaultProps = defaultProps;
ScoreAvatar.propTypes = propTypes;

export default ScoreAvatar;
